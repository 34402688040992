<template>
<div class="main">
  <div class="mainheard"></div>
  <div class="heardback">
    <div class="heardbackmid">
    </div>
  </div>
  <div class="content">
     <div>
       <router-view></router-view>
     </div>
    <div class="contentinrduse" style="position: relative;padding-left: 30px;padding-top: 20px;padding-right: 20px">
      <p style="color:rgb(11,93,166);font-weight: bolder "> 鑫冠花卉城</p>
<!--      <p> 鑫冠花卉城</p>-->
      <p> 少于50人</p>
      <p style="font-weight: bolder"> 呼和浩特市新城区成吉思汗大街街道办事处乌兰不浪村南鑫冠花卉城(开车搜索鑫冠休闲农庄，公交103路在鑫冠休闲农庄站下)</p>
      <span style="display: block;width: 90%;height: 2px; background-color: gray;position: absolute;top: 30%;left: 5%;">

      </span>
      <div style="margin-top: 20%;margin-bottom: 30px">

        <p>单位简介</p>
        <p style="line-height: 30px">
          呼和浩特鑫冠种养植农民专业合作社2011年5月成立，位于乌兰不浪村南，占地面积220亩，建有3000平米智能连栋温室1栋、日光温室45栋、农牧业科技综合培训中心1处、保鲜库300平米，配套库房1818平米。辐射了周边哈拉更、哈拉沁、乌兰不浪、生盖营和讨思浩等村，带动各村庄增加收入，合作社每年解决周边村剩余劳动力30名。合作社现有市级科技特派员1名、科技站点1处、科技培训中心1处,被评为新城区农业科技综合服务站、农技推广示范基地、科技特派员创新创业示范基地、呼和浩特革命老区农业科技示范基地、支持老区脱贫攻坚先进单位等。
        </p>
      </div>
    </div>
  </div>
  <Footer></Footer>
</div>
</template>

<script>
import Footer from "@/components/Footer";
export default {
  name: "index",
  data:()=>{
    return {
      // flag:this.$store.state.flag
    }
  },
mounted() {
  console.log(this.$store.state.flag)
},
  components:{
    Footer
  }
}

</script>

<style scoped>
.main{
  width: 100%;
  /*background-color: skyblue;*/
  height: 500px;
}
.mainheard{
  width: 100%;
  height: 100px;
  /*background-color: red;*/
}
.heardback{
  width: 100%;
  height: 150px;
  background:url('../../assets/zhaopingbackground.jpg') no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
}
.heardbackmid{
  width: 800px;
  height: 150px;
  margin: auto;
  color: white;
  text-align: center;
}
.content{
  width: 90%;
  height: 700px;
  /*background-color: pink;*/
  margin:auto;
  display: flex;
  justify-content:space-between;
  margin-top: 20px;
}
.content>div:nth-child(1){
  width: 75%;
  height: 100%;
  /*background-color: red;*/

}
.content>div:nth-child(2){
  width: 25%;
  height: 100%;
  /*background-color:bisque;*/
  background: url("../../assets/shukuang.png");
  background-size: 100% 100%;
  padding-right: 20px;
}
.contentinrduse>p{
  margin-bottom: 20px;
}
</style>