<template>
<div class="main">
  <div class="mainheard"></div>
  <div class="maintopimg">
    <div class="maintopimgword">
<!--      <h1 style="margin-bottom: 20px;font-size: 80px">INTRODUCTION</h1>-->
<!--      <h2 style="font-size: 50px;letter-spacing: 15px">企业简介</h2>-->
    </div>
  </div>
  <div class="contactus">
    <div>
      <img src="../../assets/contactus02.jpg">
    </div>
    <div>
      <img src="../../assets/contactus03.jpg">
    </div>
    <div>
      <img src="../../assets/contactus04.jpg">
    </div>
  </div>
  <div class="mapposition" id="allmap">
  </div>
  <Footer></Footer>
</div>
</template>

<script>
import Footer from "@/components/Footer";
import Map from "@/components/Map";
export default {
  name: "index",
  mounted() {
    let BMapGL=window.BMapGL
    var map = new BMapGL.Map("allmap");
    map.centerAndZoom(new BMapGL.Point(111.78064 , 40.91745 ), 17);
    map.enableScrollWheelZoom(true);
    map.setHeading(0);
    map.setTilt(50);
    // 创建点标记
    var marker1 = new BMapGL.Marker(new BMapGL.Point(111.78064,40.91745));
// 在地图上添加点标记
    map.addOverlay(marker1);
  },
  components:{
    Footer,
    Map
  }
}
</script>

<style scoped>
.main{
  width: 100%;
  /*background-color: skyblue;*/
  height: 500px;
}
.mainheard{
  width: 100%;
  height: 100px;
  /*background-color: rgb(247,246,251);*/
}
.maintopimg{
  width: 100%;
  height: 500px;
  /*background-color: bisque;*/
  background: url("../../assets/contactus.jpg");
  background-size: 100% 100%;
}
.maintopimgword{
  width: 90%;
  height: 50%;
  /*background-color: gray;*/
  margin: auto;
  transform: translateY(70%);
  text-align: center;
  color: white;
  padding-top: 30px;
}
.contactus{
  width: 85%;
  height: 400px;
  /*background-color: #ef342a;*/
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contactus>div{
  height: 300px;
  height: 300px;
  width: 300px;
}
.contactus>div>img{
  height: 300px;
  width: 300px;
}
.mapposition{
  width: 80%;
  height: 800px;
  /*background: url("../../assets/positionmap.png") no-repeat;*/
  background-size: 100% 100%;
  margin: auto;

}
.map {
  width: 400px;
  height: 300px;
}
</style>