import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    flag:0
  },
  getters: {
  },
  mutations: {
    changeflag(state,value){
      state.flag=value;
    }
  },
  actions: {
  },
  modules: {
  }
})
