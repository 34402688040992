<template>
<div class="maininfo">
  <div class="maininfotop">
    <span><img src="../../assets/jingyan.png"> {{datainfo.workExperience
      }}</span>
    <span><img src="../../assets/xueli.png">{{datainfo.education}}</span>
    <span><img src="../../assets/zhic.png">职称不限</span>
  </div>
  <div class="maininfomid">
    <p style="font-weight: bolder;font-size: 20px;color: rgb(14,100,173);margin-bottom: 20px">{{datainfo.title}}</p>
    <p style="margin-bottom: 20px;font-weight: bolder">工作内容</p>
    <div v-html="datainfo.content"></div>
  </div>
  <div class="maininfomid">

    <p style="margin-bottom: 20px;font-weight: bolder">工作地址</p>
    <div v-html="datainfo.address"></div>
  </div>
</div>
</template>

<script>

import axios from "@/utils/request";

export default {
  name: "index",
  data:()=>{
    return {
     datainfo:{}
    }
  },
  mounted() {
    console.log()
    axios.get('/renren-api/api/Recruit/xjyrecruit/'+this.$route.query.id).then((res)=>{
      if(res.status=='200'){

        this.datainfo=res.data.data

      }
    })
  }
}
</script>

<style scoped>
.maininfo{
  width: 100%;
  height: 500px;
  background-color: red;
  background: url("../../assets/infoboder.png");
  background-size: 100% 100%;
  padding: 3%;
}
.maininfotop{

}
.maininfotop>span{
  display: inline-block;
  margin-left: 30px;

}
.maininfotop>span>img{
  vertical-align:middle;
  width: 20px;
  height: 20px;

}
.maininfomid{
  margin-top: 50px;
  padding: 20px;
}
</style>