<template>
  <div>
    <baidu-map :center="center" :zoom="zoom">
      <!-- 这里可以添加其他地图覆盖物或标记 -->
    </baidu-map>
  </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map';

export default {
  components: {
    BaiduMap,
  },
  data() {
    return {
      center: { lng: 116.404, lat: 39.915 }, // 地图中心点坐标
      zoom: 15, // 缩放级别
    };
  },
};
</script>

<style scoped>
/* 这里可以放置组件的样式 */
</style>
