<template>

  <div>
    <div class="foreground">
      <!--    <div class="foregroundtop"></div>-->
      <div class="foregroundfooter">
        <div class="Recruitmentinfo" v-for="item in datalist" :key="item.id">
          <div class="Recruitmentinfocontent" style="">
            <div >
              <img :src="item.headPicture" width="98%" height="98%">
            </div>
            <div>
              <p>招商名称：{{item.name}}</p>
              <p>发布时间:{{item.createDate}}</p>
              <p>发布者:{{item.creator}}</p>
              <p>招商类型:{{item.attractInvestmentType}}</p>
            </div>
            <div>
              <a href="" @click="toinfo(item)">查看详情&nbsp; ></a>
            </div>
          </div>
          <div style="letter-spacing: 3px" v-html="item.content">
          </div>
        </div>


      </div>

    </div>
    <div style="display: flex;justify-content: center">
      <el-pagination
          background
          layout="prev, pager, next"
          :page-size="pagesize"
          :current-page="page"
          :total="total"
          @current-change="skppage"
      >
      </el-pagination>

    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import axios from "@/utils/request";
import AttractinvestmentList from "@/components/AttractinvestmentList";
export default {
  name: "index",
  data:()=>{
    return {
      pagesize: 6,
      page: 1,
      datalist: [],
      total: '',
    }
  },
  mounted() {
    this.getlist()
  },
  computed:{
    isEdit () {
      return this.$store.state.flag;　　//需要监听的数据
    }
  },
destroyed() {
  this.$store.commit('changeflag',0);
},
  watch:{
    isEdit(newVal, oldVal) {

      this.getlist()
    }
  },
  methods:{
    getlist(){
      axios.get('renren-api/api/AttractInvestment/xjyattractinvestment/page?'+'limit='+this.pagesize+'&page='+this.page).then((res)=>{
        this.datalist=[]
        if(res.status=='200'){
          if(this.$store.state.flag==0){
            this.datalist=res.data.data.list;
            this.total=res.data.data.total;
            this.$nextTick()
          }
          else if(this.$store.state.flag==1){
            res.data.data.list.map((item)=>{
              if(item.attractInvestmentType==1){
                this.datalist.push(item)
              }
            })
            this.$nextTick()
          }
          else if(this.$store.state.flag==2){
            res.data.data.list.map((item)=>{
              if(item.attractInvestmentType==2){
                this.datalist.push(item)
              }
            })
            this.$nextTick()
          }
          else if(this.$store.state.flag==3){
            res.data.data.list.map((item)=>{
              if(item.attractInvestmentType==3){
                this.datalist.push(item)
              }
            })
            this.$nextTick()
          }
        }
      })
    },
    skppage(e){
      this.page=e;
      this.getlist()
    },
    toinfo(res){
      this.$router.push(  { path: '/attractinvestment/info', query: res })
    }
  },
}
</script>

<style scoped>
.foreground>div:nth-child(1){
  width: 90%;
  /*height: 400px;*/
  /*background-color: green;*/
  margin: auto;
  display: flex;
  /*justify-content: center;*/
  flex-wrap: wrap;
}
.Recruitmentinfo{
  width: 760px;
  height: 400px;
  /*background-color: gold;*/
  background: url("../../assets/bigboder.png");
  background-size: 100% 100%;
  /*margin-bottom: 50px;*/
}
.Recruitmentinfo>div{
  width: 95%;
  height: 45%;
  margin-top: 20px;
  margin-left: 50%;
  transform: translateX(-50%);
  /*background-color: green;*/
}
.Recruitmentinfocontent{
  display: flex;
  justify-content:space-evenly;

}
.Recruitmentinfocontent>div{
  margin-top: 20px;
  /*background-color: pink;*/
}
.Recruitmentinfocontent>div:nth-child(1){
  height: 150px;
  width: 150px;
  background: url("../../assets/minboder.png");
  background-size: 100% 100%;
}
.Recruitmentinfocontent>div:nth-child(2){
  height: 180px;
  width: 360px;
}
.Recruitmentinfocontent>div:nth-child(2)>p{
  margin-bottom: 10px;
  font-weight: bolder;
}
.Recruitmentinfocontent>div:nth-child(3){
  height: 180px;
  width: 150px;

}
.Recruitmentinfocontent>div:nth-child(3)>a {
  text-decoration: none;
  color: rgb(24, 116, 193);
}
</style>