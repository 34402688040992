import Vue from 'vue'
import VueRouter from 'vue-router'
import Mainpage from "@/views/Mainpage";
import Attractinvestment from "@/views/Attractinvestment";
import Contactus from "@/views/Contactus";
import Introduction from "@/views/Introduction";
import Recruitment from "@/views/Recruitment";
import RecruitmentList from "@/components/RecruitmentList";
import Recruitmentinfo from "@/components/Recruitmentinfo";
import AttractinvestmentList from "@/components/AttractinvestmentList";
import AttractinvestmentInfo from "@/components/AttractinvestmentInfo";
import Lastnews from "@/views/Lastnews";
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: Mainpage
  },
  {
    path: '/introduction',
    name: 'Introduction',
    component: Introduction
  },
  {
    path: '/recruitment',
    name: 'Recruitment',
    component: Recruitment,
    redirect: '/recruitment/list',
    children:[
      {name:'Recruitment',path:'/recruitment/list', component: RecruitmentList},
      {name:'Recruitment',path:'/recruitment/info', component: Recruitmentinfo},
    ]
  },
  {
    path: '/attractinvestment',
    name: 'Attractinvestment',
    component: Attractinvestment,
    redirect: '/attractinvestment/list',
    children:[
      {name:'Attractinvestment',path:'/attractinvestment/list', component: AttractinvestmentList},
      {name:'Attractinvestment',path:'/attractinvestment/info', component: AttractinvestmentInfo},
    ]
  },{
    path: '/contactus',
    name: 'Contactus',
    component: Contactus
  },{
    path: '/Lastnews',
    name: 'Lastnews',
    component: Lastnews
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
