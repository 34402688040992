<template>
<div class="main2">
   <div class="mid">
     <div style="width: 100%; height: 65%;border-bottom: 2px solid gray" class="heard">
       <div style="width:100px; height: 100%;">
         <img src="../../assets/LOGO.png" style="width:100px; height:100px;margin-bottom: 5px">
         <h4 style="width:100%;  color: white;font-weight: lighter; font-size: 10px;text-align: center"> 关注我们</h4>
       </div>
       <div style="width:300px; height: 100%;padding-top: 20px;font-weight: lighter;" class="h4father">
         <h4>电话</h4>
         <h4>15647161199</h4>
         <h4>微信</h4>
         <h4>15647161199</h4>
         <h4>总经理：李吉</h4>
       </div>
       <div style="width:450px; height: 100%;padding-top: 20px" class="h4father">
         <h4>地址</h4>
         <h4>呼和浩特市新城区成吉思汗大街街道办事处乌兰不浪村南鑫冠花卉城(开车搜索鑫冠休闲农庄，公交103路在鑫冠休闲农庄站下)</h4>
       </div>

       <div style="width:450px; height: 100%;padding-top: 20px" class="h4father">
         <h4>地址</h4>
         <h4>呼和浩特市新城区成吉思汗大街街道办事处乌兰不浪村南鑫冠花卉城(开车搜索鑫冠休闲农庄，公交103路在鑫冠休闲农庄站下)</h4>
       </div>
       <div style="width:150px; height: 100%;">
         <img src="../../assets/LOGO.png" style="width:100px; height:100px;margin-bottom: 10px">
       </div>
     </div>
     <div>
         <h3 style="width: 100%; text-align: center;color: white; font-weight: lighter;">
           版权所有: 内蒙古鑫冠花卉有限责任公司
         </h3>
       <a style="display: inline-block; width: 100%; text-align: center;color: white; font-weight: lighter;text-decoration: none"  target=“_blank”
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery">蒙ICP备2023001500号-1</a>
     </div>
   </div>
</div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped>
.main2{
  width: 100%;
  height: 240px;
  background-color: rgb(38,38,38);
  display: flex;
  justify-content: center;
  align-items: center;
}
.mid{
  width: 85%;
  height: 85%;
  /*background-color: red;*/


}
.heard{
  display: flex;
  justify-content: space-between;
}
.h4father>h4{
  margin-bottom: 10px;
  padding-left: 20px;
  color: white;
  font-weight: lighter;
  font-size: 10px;
}
</style>