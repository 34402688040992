<template>
  <div class="main">
    <div class="mainheard"></div>
    <div class="maintopimg">
      <div class="maintopimgword">
        新闻与活动
      </div>
    </div>
    <div class="searchbox" v-if="showflag">
      <el-input placeholder="搜索新闻" v-model="input3" class="input-with-select">
        <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
      </el-input>
    </div>
    <div class="lastnews" v-if="showflag">
      <div class="lastnewscontent">
          <div class="lastnewscontent-item" v-for="item in datalsit" @click="iteminfo(item)">
           <div class="item-img">
             <img :src="item.titlePictrue" style="width: 100%;height: 100%;">
           </div>
            <div class="item-title">
              <h3>{{item.title}}</h3>
            </div>
          </div>

      </div>
        <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="pagesize"
            @current-change="change"
            style="margin-left: 50%;transform: translateX(-50%)"
        >
        </el-pagination>

    </div>
    <div class="lastnews" v-if="!showflag">
      <div style="width: 100%;height: 30px;margin-bottom: 30px">
        <button class="comeback" style="width: 100px;height: 40px;float: right;border: none;" @click="comeback">返回</button>
      </div>
      <h2 style="width: 100%;text-align: center">
        {{datainfon.title}}
      </h2>
      <h4 style="width: 100%;text-align: center">{{datainfon.createDate}}</h4>
      <div v-html="datainfon.content" style="overflow-y: auto;height: 100%;width: 100%;"></div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import axios from "@/utils/request";
export default {
  name: "index",
  data:()=>{
    return{
      input3:'',
      pagesize:6,
      page:1,
      total:1,
      datalsit:[],
      showflag:true,
      datainfon:{}
    }
  },
  mounted() {
    axios.get('renren-api/api/Info/xjyinfo/page?'+'limit='+this.pagesize+'&page='+this.page).then((res)=>{
      if(res.status){
        this.total=res.data.data.total
        this.datalsit=res.data.data.list
      }
    })
  },
  methods:{
    search(id){
      axios.get('renren-api/api/Info/xjyinfo/'+this.input3).then((res)=>{
        console.log(res)
        // if(res.status){
        //   this.total=res.data.data.total
        //   this.datalsit=res.data.data.list
        // }
      })
    },
    change(e){
      this.getdatalist(e)
    },
    getdatalist(page){
      axios.get('renren-api/api/Info/xjyinfo/page?'+'limit='+this.pagesize+'&page='+page).then((res)=>{
        if(res.status){
          this.total=res.data.data.total
          this.datalsit=res.data.data.list
        }
      })
    },
    iteminfo(item){
      console.log(item)
      this.showflag=false
      this.datainfon=item
    },
    comeback(){
      this.showflag=true
    }
  },
  components:{
    Footer
  }
}
</script>

<style scoped>
.main{
  width: 100%;
  /*background-color: skyblue;*/
  height: 500px;
}
.mainheard{
  width: 100%;
  height: 100px;
  /*background-color: rgb(247,246,251);*/
}
.maintopimg{
  width: 100%;
  height: 500px;
  /*background-color: bisque;*/
  background: url("../../assets/News_list1.jpg");
  background-size: 100% 100%;
}
.maintopimgword{
  width: 90%;
  height: 50%;
  /*background-color: gray;*/
  margin: auto;
  transform: translateY(70%);
  text-align: center;
  color: white;
  padding-top: 30px;
  font-size: 30px;
  letter-spacing: 10px;
}
.searchbox{
  width: 85%;
  margin: auto;
}
.lastnews{
  width: 85%;
  height: 1000px;
  margin: auto;
}

.lastnewscontent{
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start ;
}
.lastnewscontent-item{
  width: 450px;
  height: 350px;
  margin-right: 80px;
  margin-top: 50px;
}
.item-img{
  width: 100%;
  height: 80%;
  background: url("../../assets/News_list1.jpg");
 background-size: 100% 100%;
}
.item-title{
  width: 100%;
  height: 20%;
  background-color: rgba(220,220,220,0.5);
}
.comeback{
  background-color: rgba(220,220,220,0.5)
}

.comeback:hover{
  background-color: #ef342a;
}
</style>