<template>
  <div id="app">
   <Top></Top>
    <router-view/>
<!--    <Footer></Footer>-->
  </div>
</template>
<script>
import Top from "@/components/Top";
import Footer from "@/components/Footer";
export default {
  components:{
    Top,
    Footer
  }
}
</script>
<style lang="scss">
*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
*::-webkit-scrollbar {

  width: 0px;

}
</style>
