<template>
<div class="maininfo2">
  <div class="black" @click="blackhome">返回</div>
<h3 class="maininfo2h3">{{datainfo.name}}</h3>
  <h4 class="maininfo2h4">{{datainfo.createDate}}</h4>
  <p style="letter-spacing: 3px" v-html="datainfo.content"></p>
</div>
</template>

<script>
export default {
  name: "index",
  data:()=>{
    return{
      datainfo:{}
    }
  },
  mounted() {
    this.datainfo=this.$route.query
  },
  methods:{
    blackhome(){
      this.$router.push('/attractinvestment/list')
    }
  }
}
</script>

<style scoped>
.maininfo2{
  width: 90%;
  height: 400px;
  margin: auto;
  text-align: center;
  position: relative;
  padding-top: 30px;
}
.maininfo2h3{
  margin-bottom: 10px;
}
.maininfo2h4{
  margin-bottom: 10px;
}
.black{
  width: 100px;
  position: absolute;
  right: 0px;
  top: 0px;
}
.black:hover{
  color: #ef342a;
  width: 100px;
}
</style>