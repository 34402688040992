import axios from "axios";
const api=axios.create({
    //配置对象
    //基础的axios地址
    baseURL:'/api',
    //超过的时间
    timeout:5000,
    headers:{'X-Custom-Hwader':'foobar'}
})
api.interceptors.request.use((config)=>{

    return config;
},(error)=>{
    return Promise.reject(new Error('faile'))
})
api.interceptors.response.use((response)=>{

    return response
},(error) => {
    return Promise.reject(new Error('faile'))
})
export default api