<template>
<div class="main">
  <el-carousel indicator-position="outside" height="1000px">
    <el-carousel-item v-for="item in imglist" :key="item" >
      <img :src="item" alt="" width="100%" height="100%">
    </el-carousel-item>
  </el-carousel>
  <div>
  </div>
  <div class="contain">
    <div class="containtop">
      <h1>Company Profile</h1>
      <h1>企业简介</h1>
    </div>
    <div class="containtfooter">

      <div class="containtfootertwo">
        <div style="width:30%">
          {{info}}
          <div style="margin-top: 30px">
           <img src="../../assets/shuangyhao.png">
            <div style="color:gray">最好的食品优选</div>
            <div style="color:gray">The best choice for food</div>
          </div>
        </div>
        <div style="width:67% ;height: 100%;">
          <img src="../../assets/flower/5.jpg" width="100%" height="90%">
        </div>
      </div>
    </div>

  </div>
  <div class="reason">
    <div class="reasonmid">
      <div class="reasonmidtop">
        <h1>  Advantages</h1>
        <h2 style="margin-bottom: 50px"> 选择我们的四大理由</h2>
        <h2>综合实力</h2>
        <h3 style="margin-bottom: 20px">Comprehensive strength</h3>
        <h4> 文字案例文字案例文字案例文字案例文字案例文字案例文字案例文字案例文字案例文字案例文字案例文字案例</h4>

      </div>
      <div class="reasonmidceter">
        <div>
          <img src="../../assets/comprehensivestrength.png" width="100%">
        </div>
        <div>
          <img src="../../assets/productcategory.png" width="100%">
        </div>
        <div>
          <img src="../../assets/professionalteam.png" width="100%">
        </div>
        <div>
          <img src="../../assets/intimateservice.png" width="100%">
        </div>
      </div>
      <div class="reasonmidtopfooter">
        <div class="zaixiandianhua" style="width: 550px; height: 200px;position: absolute;left: 100px;bottom: 0;">
          <img src="../../assets/onlineconsultant.png">
          <img src="../../assets/phone.png">
          <div style="font-weight: bolder;font-size: 20px;letter-spacing: 25px;padding-left:70px">我们为你贴心服务!</div>
        </div>
      </div>
    </div>
  </div>
  <div class="Recruitment">
    <div class="Recruitmentmid">
      <div style="width: 100%;height: 20%;">
        <h1 style="letter-spacing: 5px;margin-bottom: 10px">Recruitment information</h1>
        <h1 style="letter-spacing: 5px">招聘信息</h1>
      </div>
      <div class="Recruitmentfooter">
        <div class="Recruitmentinfo" v-for="item in datalist">
          <div class="Recruitmentinfocontent" style="">
            <div >
<!--           <img :src="item.">-->
            </div>
            <div>
<!--              <p>内蒙古鑫冠规划测绘有限责任公司</p>-->
              <p>岗位:{{item.title}}</p>
              <p>薪资:xxXxX</p>
              <p>联系电话:{{item.tel}}</p>
            </div>
            <div>
            <a href="" @click="toinforecruitment(item.id)">查看详情&nbsp; ></a>
            </div>
          </div>
          <div style="letter-spacing: 3px" >
          <span style="font-weight: bolder">招聘要求</span>
            <div v-html="item.content"></div>
          </div>

        </div>

      </div>
    </div>
  </div>
  <Footer></Footer>
</div>
</template>

<script>
import Footer from "@/components/Footer";
import axios from "@/utils/request";
const sucai1=require('../../assets/flower/1.jpg');
const sucai2=require('../../assets/flower/3.jpg')
const sucai3=require('../../assets/flower/4.jpg')
const sucai4=require('../../assets/flower/5.jpg')
const sucai5=require('../../assets/flower/7.jpg');
const sucai6=require('../../assets/flower/9.jpg')
const sucai7=require('../../assets/flower/11.jpg')
const sucai8=require('../../assets/flower/12.jpg')
export default {
  name: "index",
  data:()=>{
    return {
      imglist:[sucai2,sucai1,sucai3,sucai4,sucai5,sucai6,sucai7,sucai8],
      info:'呼和浩特鑫冠种养植农民专业合作社2011年5月成立，位于乌兰不浪村南，占地面积220亩，建有3000平米智能连栋温室1栋、日光温室45栋、农牧业科技综合培训中心1处、保鲜库300平米，配套库房1818平米。辐射了周边哈拉更、哈拉沁、乌兰不浪、生盖营和讨思浩等村，带动各村庄增加收入，合作社每年解决周边村剩余劳动力30名。合作社现有市级科技特派员1名、科技站点1处、科技培训中心1处,被评为新城区农业科技综合服务站、农技推广示范基地、科技特派员创新创业示范基地、呼和浩特革命老区农业科技示范基地、支持老区脱贫攻坚先进单位等。\n' +
          '合作社一直以传统种植蔬菜和新品种引进为主。因三年疫情，对该模式的冲击比较大，故开始筹划引进适合家庭、社会团体花卉批发、培育，形成花卉产供销一体化模式。花卉城预计总投资500万元，种植培育有1000余品种，3000平方米智能温室主要用于花卉展示、新型种植技术推广和研学基地的展示窗口等；20栋日光温室用于种植引进各类花卉。带动就业30余人。\n' +
          '合作社致力于打造以高端花卉种植、培育、研发等产销一体化模式为主，兼有其他种类的多品种果蔬休闲采摘、观光体验、农牧业科技培训于一体的鑫冠农业示范基地。',
      datalist:[]
    }
  },
  mounted() {
    this.getliast()
  },
  methods:{
    getliast(){
      axios.get('/renren-api/api/Recruit/xjyrecruit/page?'+'limit='+4+'&page='+1).then((res)=>{
        if(res.status=='200'){
          this.datalist=res.data.data.list;
        }
      })
    },
    toinforecruitment(value){
      this.$router.push('/recruitment/info?id='+value)
    }
  },
  components:{
    Footer
  }
}
</script>

<style scoped>

.main{
  /*position: absolute;*/
  top: 0px;
  z-index: -1;
  width: 100%;
  /*background-color: skyblue;*/
  height: 800px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
.elcarousel{
  height: 100%;
}
.el-carousel__item:nth-child(2n) {
  /*background-color: #99a9bf;*/
}

.el-carousel__item:nth-child(2n+1) {
  /*background-color: #d3dce6;*/
}
/deep/.el-carousel__indicators{
  position: absolute;
  bottom: 0px;
}
/deep/.el-carousel__button{
  /*background-color: red;*/
}
.contain{
  width: 100%;
  height: 700px;
  /*background-color: red;*/
}
.containtop{
  width: 100%;
  height: 20%;
  /*background-color:skyblue;*/
  padding-top: 60px;
}
.containtfooter{
  width: 100%;
  height: 80%;
  margin-top: 50px;
}
.containtop>h1{
  width: 90%;
  margin: auto;
  letter-spacing: 5px;
  /*padding-top: 30px;*/
}
.containtfootertwo{
  width: 90%;
  height: 100%;
  /*background-color: green;*/
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.containtfootertwo>div:nth-child(1){
  /*background-color: blue;*/
  line-height: 30px;
  letter-spacing: 3px;
}
.reason{
  width: 100%;
  background: url("../../assets/background.jpg") no-repeat;
  background-size: 100% 100%;
  height: 1200px;
  display: flex;
  justify-content: center;

}
.reasonmid{
  width: 95%;
  height: 85%;

  margin-top: 180px;


}
.reasonmidtop{
  width: 80%;
  height: 40%;

  margin: auto;
  padding-top: 100px;
  padding-left: 90px;
  color: white;
}
.reasonmidceter{
  width: 80%;
  height: 30%;

  margin: auto;
  display: flex;
  align-items: center;
}
.reasonmidceter>div{
  width: 200px;
  height: 200px;
  margin-left: 80px;
}
.reasonmidceter>div:nth-child(3){
  margin-top: -100px;
}
.reasonmidtopfooter{
  width: 80%;
  height: 30%;
  margin: auto;
  position: relative;
}
.zaixiandianhua>img{
  margin-left: 60px;
  margin-bottom: 20px;
}
.Recruitment{
  width: 100%;
  height: 600px;
  margin-top: 50px;
}
.Recruitmentmid{
  width: 90%;
  height: 100%;
  /*background-color: red;*/
  margin: auto;

}
.Recruitmentfooter{
  display: flex;
  justify-content: space-between;
}
.Recruitmentinfo{
  width: 760px;
  height: 400px;
  /*background-color: gold;*/
  background: url("../../assets/bigboder.png");
  background-size: 100% 100%;
  /*margin-bottom: 50px;*/
}
.Recruitmentinfo>div{
  width: 95%;
  height: 45%;
  margin-top: 20px;
  margin-left: 50%;
  transform: translateX(-50%);
  /*background-color: green;*/
}
.Recruitmentinfocontent{
  display: flex;
  justify-content:space-evenly;

}
.Recruitmentinfocontent>div{
margin-top: 20px;
  /*background-color: pink;*/
}
.Recruitmentinfocontent>div:nth-child(1){
  height: 150px;
  width: 150px;
  background: url("../../assets/minboder.png");
  background-size: 100% 100%;
}
.Recruitmentinfocontent>div:nth-child(2){
  height: 180px;
  width: 360px;
}
.Recruitmentinfocontent>div:nth-child(2)>p{
  margin-bottom: 20px;
  font-weight: bolder;
}
.Recruitmentinfocontent>div:nth-child(3){
  height: 180px;
  width: 150px;

}
.Recruitmentinfocontent>div:nth-child(3)>a{
  text-decoration: none;
  color: rgb(24,116,193);
}
</style>