<template>
<div class="main">
  <div class="mainheard"></div>
  <div class="maintopimg">
    <div class="maintopimgword">
      <h1 style="margin-bottom: 20px;font-size: 80px">INTRODUCTION</h1>
      <h2 style="font-size: 50px;letter-spacing: 15px">企业简介</h2>
    </div>
  </div>
   <div class="maincontent">
     <div class="maincontenttop">
       <p style="font-size: 25px;font-weight: bolder;color: rgb(15,101,174);letter-spacing: 10px">呼和浩特市鑫冠种养植农民专业合作社</p>
       <p style="font-size: 15px;color: gray;letter-spacing: 5px">ABOUT Xinguan</p>
     </div>
     <div class="maincontentmid" style="letter-spacing: 5px;width: 85%;margin: auto;margin-top: 20px;color: gray">
       呼和浩特鑫冠种养植农民专业合作社2011年5月成立，位于乌兰不浪村南，占地面积220亩，建有3000平米智能连栋温室1栋、日光温室45栋、农牧业科技综合培训中心1处、保鲜库300平米，配套库房1818平米。辐射了周边哈拉更、哈拉沁、乌兰不浪、生盖营和讨思浩等村，带动各村庄增加收入，合作社每年解决周边村剩余劳动力30名。合作社现有市级科技特派员1名、科技站点1处、科技培训中心1处,被评为新城区农业科技综合服务站、农技推广示范基地、科技特派员创新创业示范基地、呼和浩特革命老区农业科技示范基地、支持老区脱贫攻坚先进单位等。
       合作社一直以传统种植蔬菜和新品种引进为主。因三年疫情，对该模式的冲击比较大，故开始筹划引进适合家庭、社会团体花卉批发、培育，形成花卉产供销一体化模式。花卉城预计总投资500万元，种植培育有1000余品种，3000平方米智能温室主要用于花卉展示、新型种植技术推广和研学基地的展示窗口等；20栋日光温室用于种植引进各类花卉。带动就业30余人。
       合作社致力于打造以高端花卉种植、培育、研发等产销一体化模式为主，兼有其他种类的多品种果蔬休闲采摘、观光体验、农牧业科技培训于一体的鑫冠农业示范基地。
     </div>
     <div class="maincontentfooter" >
       <div style=" background-color: #ef342a; grid-row: 1 / 2; grid-column: 1 / 3; ">
         <img src="./../../assets/flower/2.jpg">
       </div>
       <div >
         <img src="./../../assets/flower/12.jpg">
       </div>
       <div >
         <img src="./../../assets/07.jpg">
       </div>
       <div style="grid-row: 1 / 3; grid-column: 3 / 4; ">
         <video width="100%" height="100%" controls>
           <source src="./../../assets/flower/video.mp4" type="video/mp4">
           您的浏览器不支持视频标签。
         </video>
       </div>
       <div>
         <img src="./../../assets/flower/15.jpg">
       </div>
       <div >
         <img src="./../../assets/04.jpg">
       </div>
       <div style=" background-color: #ef342a; grid-row: 2 / 3; grid-column: 4 / 6; ">
         <img src="./../../assets/flower/16.jpg">
       </div>

     </div>
   </div>
  <div class="bigevent">
   <div class="bigeventfooter"></div>
  </div>
  <div class="latestnews">
    <div class="maincontenttop">
      <div>
        <p style="font-size: 25px;font-weight: bolder;color: rgb(15,101,174);letter-spacing: 10px">最近资讯</p>
        <p style="font-size: 15px;color: gray;letter-spacing: 5px">Information</p>
      </div>
      <div class="latestnewscontent">
        <div style="width: 700px; height: 200px;position: absolute;left: 0px">
          <img src="./../../assets/18.jpg" style="width: 100%; height: 100%;">
        </div>
      <div style="width: 80%; height: 420px;position: absolute;left: 100px;top: 180px">
        <img src="./../../assets/17.jpg" style="width: 100%; height: 100%;" >
        <div style="position: absolute;top: 50px;left: 100px;width: 450px">
          <p style="text-align: left;color: white;margin-bottom: 10px;letter-spacing: 5px;font-weight: bolder"> 合作共赢，共建美好生活愿景，鑫疆源签署战略协议实现双方合作</p>
          <p style="text-align: left;color: white;margin-bottom: 10px">2023.8.12</p>
          <p style="text-align: left;color: white;font-size: 15px;letter-spacing: 5px;">合作共赢，共建美好生活愿景，鑫疆源签署战略协议合作共赢，共建美好生活愿景鑫疆源签署战略协议实现双方合作实现双方合作</p>
        </div>
        <div class="pagechange">
          <div>
           <img src="../../assets/row-changeright.png" style="width: 100px; height: 70px;">
          </div>
          <div style="margin-top: 30px">
            <span style="font-size: 50px;color: white">{{pagenum<10?'0'+(Number(pagenum)+1):pagenum}}</span>
            <span style="display: inline-block;width: 3px; height: 20px;background-color: white;margin-left: 10px;margin-right: 10px"></span>
            <span style="font-size: 25px;color: white">{{imgelist.length}}</span>
          </div>
        </div>
      </div>
       <div style="width: 55%; height: 500px;position: absolute;right: 0px;top: 0px">
         <img :src="imgelist[pagenum]" style="width: 100%; height: 100%;">
         <div class="changeimage">
           <div><i class="el-icon-arrow-left" @click="arrowleft"></i></div>
           <div><i class="el-icon-arrow-right" @click="arrowright"></i> </div>
         </div>
       </div>

      </div>
    </div>
  </div>
<!--  <div class="footerword">-->
<!--    <p> 中国，正迎来城市化的黄金时期，鑫疆源，将全面参与与中国城市化进程，开始共建，共生，共享的明日之城</p>-->
<!--    <p style="font-size: 12px"> China is entering a golden period of urbanization, and Xmnjiangyuan wil fuly participate in the process of urbanizaton in China, tarting to buld, coexist and share the chty of tomoroy</p>-->
<!--   </div>-->
  <Footer></Footer>
</div>

</template>

<script>
// import axios from "@/utils/request";
import Footer from "@/components/Footer";
const sucai1=require('../../assets/flower/1.jpg');
const sucai2=require('../../assets/flower/3.jpg')
const sucai3=require('../../assets/flower/4.jpg')
const sucai4=require('../../assets/flower/5.jpg')
const sucai5=require('../../assets/flower/7.jpg');
const sucai6=require('../../assets/flower/9.jpg')
const sucai7=require('../../assets/flower/11.jpg')
const sucai8=require('../../assets/flower/12.jpg')
export default {
  name: "index",
  data(){
    return {
      pagenum:'0',
      imgelist:[sucai1, sucai2, sucai3, sucai4, sucai5, sucai6, sucai7, sucai8]
    }
  },
  mounted() {
    // console.log(axios)
  },
  methods:{
    arrowright(){
      if(this.pagenum<this.imgelist.length-1){
        this.pagenum=Number(this.pagenum)+1
      }
    },
    arrowleft(){
      if(this.pagenum>0){
        this.pagenum=Number(this.pagenum)-1
      }
    }
  },
  components:{
    Footer
  }
}
</script>

<style scoped>
.main{
  width: 100%;
  /*background-color: skyblue;*/
  height: 500px;
}
.mainheard{
  width: 100%;
  height: 100px;
}
.maintopimg{
  width: 100%;
  height: 500px;
  background-color: bisque;
  background: url("../../assets/flower/5.jpg");
  background-size: 100% 100%;
}
.maintopimgword{
  width: 90%;
  height: 50%;
  /*background-color: gray;*/
  margin: auto;
  transform: translateY(70%);
  text-align: center;
  color: white;
  padding-top: 30px;
}
.maincontent{
  width: 90%;
  height: 850px;
  /*background-color: red;*/
  margin: auto;
  padding-top: 20px;
}
.maincontenttop{
  text-align: center;
  /*background-color: gray;*/
  font-size: 20px;
}
.maincontentfooter{
  width: 85%;
  height: 600px;
  /*background-color: pink;*/
  margin: auto;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 280px 280px 280px 280px 280px;
  grid-template-rows: 290px 290px;
  justify-content: space-between;
  align-content: space-between;
}
.maincontentfooter>div{
  /*background-color: green;*/
  /*border: 1px solid black;*/
  border-radius: 10px;
}
.maincontentfooter>div>img{
  width: 100%;
  height: 100%;
}
.bigevent{
  width: 80%;
  height: 700px;
  /*background-color: green;*/
  margin: auto;
  text-align: center;
  background: url("./../../assets/09.jpg");
  background-size: 100% 100%;
  position: relative;
}
.bigeventfooter{
  position: absolute;
  width: 100%;
  height: 80px;
  background-color: rgba(0,0,0,0.3);
  bottom: 0px;
}
.latestnews{
  width: 80%;
  height: 700px;
  /*background-color: green;*/
  margin: auto;
  text-align: center;
  /*background: url("./../../assets/09.jpg");*/
  background-size: 100% 100%;
  position: relative;
}
.latestnewscontent{
  width: 100%;
  height: 600px;
  /*background-color: skyblue;*/
  position: relative;
}
.footerword{
  width: 80%;
 font-weight: bolder;
  margin: auto;
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
}
.changeimage{
  width: 130px;
  height: 50px;
  background-color: rgba(0,0,0,0.5);
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
}
.changeimage>div{
  width: 65px;
  height: 50px;
  /*background-color: #ef342a;*/
  display: flex;
  justify-content: center;
 align-items: center;
  font-size: 30px;
  color: white;
}
.changeimage>div>i:hover{
  color: deepskyblue;
}
.pagechange{
  position: absolute;
bottom: 0px;
  height: 200px;
  width: 300px;
  /*background-color: #ef342a;*/
  left: 100px;
  text-align: left;
}
</style>