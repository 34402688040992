<template>
<div class="main1">
  <div class="mainleft">
    <img class="image" src="../../assets/LOGO.png">
  </div>
  <div class="mainmid" >
    <div class="mainmidli" v-for="(item,index) in list" :key="item.title" @click="topage(item.title,item.name)" @mouseenter="showhide" :class="{active:item.name==rotename?true:false}">
      {{item.title}}
      <li class="seletion" style="position: absolute;top:50px;left:-90px; background-color: white" @mouseenter="showhide" @mouseleave="hideshow" v-if="item.name=='Attractinvestment'&&rotename=='Attractinvestment'&&flagshow?true:false">
        <span style="position: absolute;top:-20px;left:45%;display: block;width: 2px;height: 20px;background-color:  rgb(13,101,176)"></span>
        <p>
          <img :src="vegetableactive" width="80%" height="100%" @click="vegetableshow">
        </p>
        <p>
          <img :src="flower" width="80%" height="100%" @click="flowershow">
        </p>
        <p>
          <img :src="fruits" width="80%" height="100%" @click="fruitsshow">
        </p>
      </li>
    </div>

  </div>
  <div class="mainright">
    <div class="mainrightleft">
      <img src="../../assets/list.png">

    </div>
    <div class="mainrightright">
      <img src="../../assets/search.png">

    </div>
  </div>


</div>
</template>

<script>
const vegetableactivesig=require("../../assets/vegetableactive.png");
const vegetablesig=require("../../assets/vegetable.png");
const flowersig=require("../../assets/flower.png");
const flowersigactivesig=require("../../assets/flworactive.png");
const fruitssig=require("../../assets/fruits.png");
const fruitssigactivesig=require("../../assets/fruitsactive.png");
export default {
  name: "index",
  data:()=>{
    return {
      list:[ {title:'首页',name:'home'},
        {title:'公司简介',name:'Introduction'},
        {title:'招聘信息',name:'Recruitment'},
        {title:'招商加盟',name:'Attractinvestment'},
        {title:'新闻与活动',name:'Lastnews'},
        {title:'联系我们',name:'Contactus'} ],
      rotename:'home',
      flagshow:false,
      vegetableactive: vegetablesig,
      flower: flowersig,
      fruits:  fruitssig
    }
  },
  mounted() {
    this.rotename=this.$route.name
    console.log()
  },
updated() {
  this.rotename=this.$route.name
  if(this.$store.state.flag==0){
    this.vegetableactive=vegetablesig
  }
},

  methods:{
    topage(value,name){
      this.rotename=name
      if(value==='首页'&&this.$route.name !== 'home'){
     this.$router.push('/')
      }
      else if (value==='公司简介'&&this.$route.name !== 'Introduction'){
        this.$router.push('/introduction')
      }
      else if (value==='招聘信息'&&this.$router.currentRoute.path !== '/recruitment/list'){
        this.$router.push('/recruitment/list'); // 执行导航操作
      }
      else if (value==='招商加盟'&&this.$route.name !== 'Attractinvestment'){
        this.$router.push('/attractinvestment');
        this.vegetableactive=vegetableactivesig;
        this.flower=flowersig;
        this.fruits=fruitssig;
      }
      else if (value==='联系我们'&&this.$route.name !== 'Contactus'){
        this.$router.push('/contactus')
      }
      else if (value==='新闻与活动'&&this.$route.name !== 'Lastnews'){
        this.$router.push('/Lastnews')
      }
    },
    showhide(e){
      if(e.target.innerText=="招商加盟"){
       this.flagshow=true
      }

    },
    hideshow(){
      this.flagshow=false
    },
    vegetableshow(){
      this.vegetableactive=vegetableactivesig;
      this.flower=flowersig;
      this.fruits=fruitssig;
     this.$store.commit('changeflag',1);
      console.log(this.$store.state.flag)
    },
    flowershow(){
      this.vegetableactive=vegetablesig;
      this.flower=flowersigactivesig;
      this.fruits=fruitssig;
      this.$store.commit('changeflag',2);
      console.log(this.$store.state.flag)
    },
    fruitsshow(){
      this.vegetableactive=vegetablesig;
      this.flower=flowersig;
      this.fruits=fruitssigactivesig;
      this.$store.commit('changeflag',3);
      console.log(this.$store.state.flag)
    }
  }
}
</script>

<style scoped >
   .main1 {
     position: absolute;
     width: 95%;
     margin: auto;
    display: flex;
     justify-content: space-between;
    z-index: 10;
   }
   .mainleft{
     width: 15%;
     height: 100px;
     /*background-color: blue;*/
     align-content: center;
     text-align: center;
   }
   .mainmid{

     width: 70%;
     height: 100px;
     /*background-color: red;*/
     display: flex;
     justify-content: space-between;
     align-items: center;
     color: black;
     font-weight: bolder;
     font-size: 22px;
     list-style: none;
   }
   .mainmidli{
     /*width: 100%;*/
     text-align: center;
     position: relative;

   }
   .mainright{
     width: 15%;
     height: 100px;
     /*background-color: blue;*/
     display: flex;
     justify-content: right;
   }
   .mainrightleft{
     width: 100px;
     height: 100px;
     /*background-color: green;*/
     line-height: 120px;
   }
   .mainrightright{
     width: 100px;
     height: 100px;
     /*background-color: green;*/
     line-height: 120px;
   }
.image{
  height: 100%;
  width: 130px;
}
.active{
  color: rgb(13,101,176);
  border-bottom: 3px solid rgb(13,101,176);
}
.seletion{
  width: 300px;
  height: 80px;
  /*border: 1px solid black;*/
  display: flex;
  justify-content:space-evenly;
}
.seletion>p{
  width: 60px;
  height: 90%;

}
</style>