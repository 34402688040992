<template>
<div class="main">
  <div class="mainheard"></div>
  <div class="heardback">
    <div class="heardbackmid">
      <h1 style="letter-spacing: 10px;margin-bottom: 20px;margin-top: 20px"> 鑫疆源全国市场经销，代理招商中</h1>
      <h1 style="letter-spacing: 5px">财富热线: 400-000-000</h1>
    </div>
  </div>

<router-view></router-view>
<Footer></Footer>
</div>
</template>

<script>
import axios from "@/utils/request";
import Footer from "@/components/Footer";
import AttractinvestmentList from "@/components/AttractinvestmentList";
export default {
  name: "index",
  data:()=>{
    return {
      pagesize: 6,
      page: '1',
      datalist: [],
      total: '',
    }
  },
  mounted() {

  },
  methods:{

    skppage(e){
    this.page=e;
     this.getlist()
    }
  },

  components:{
    Footer,
    AttractinvestmentList
  }
}
</script>

<style scoped>
.main{
  width: 100%;
  /*background-color: skyblue;*/
  height: 500px;
}
.mainheard{
  width: 100%;
  height: 100px;
}
.heardback{
  width: 100%;
  height: 300px;
  background:url('../../assets/zhaoshangbackground.jpg') no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
}
.heardbackmid{
  width: 800px;
  height: 200px;
  margin: auto;
color: white;
  text-align: center;
}
.foreground{
  width: 100%;
  /*height:600px;*/
  /*background-color: pink;*/
}

.foreground>div:nth-child(1){
  width: 90%;
  /*height: 400px;*/
  /*background-color: green;*/
  margin: auto;
  display: flex;
  /*justify-content: center;*/
  flex-wrap: wrap;
}
.Recruitmentinfo{
  width: 760px;
  height: 400px;
  /*background-color: gold;*/
  background: url("../../assets/bigboder.png");
  background-size: 100% 100%;
  /*margin-bottom: 50px;*/
}
.Recruitmentinfo>div{
  width: 95%;
  height: 45%;
  margin-top: 20px;
  margin-left: 50%;
  transform: translateX(-50%);
  /*background-color: green;*/
}
.Recruitmentinfocontent{
  display: flex;
  justify-content:space-evenly;

}
.Recruitmentinfocontent>div{
  margin-top: 20px;
  /*background-color: pink;*/
}
.Recruitmentinfocontent>div:nth-child(1){
  height: 150px;
  width: 150px;
  background: url("../../assets/minboder.png");
  background-size: 100% 100%;
}
.Recruitmentinfocontent>div:nth-child(2){
  height: 180px;
  width: 360px;
}
.Recruitmentinfocontent>div:nth-child(2)>p{
  margin-bottom: 10px;
  font-weight: bolder;
}
.Recruitmentinfocontent>div:nth-child(3){
  height: 180px;
  width: 150px;

}
.Recruitmentinfocontent>div:nth-child(3)>a{
  text-decoration: none;
  color: rgb(24,116,193);
}
</style>