<template>
<div>
  <div class="mainlist" v-for="item in datalist" :key="item.id" @click="toinfo(item.id)">
    <div>
      <p style="margin-bottom: 10px ;color:rgb(14,100,173);font-weight: bolder;letter-spacing: 1px "> {{item.title}} </p>
      <p>  6-8K {{item.workExperience}}/{{item.education}}</p>
    </div>
    <div style="text-align: right">
      <p style="margin-bottom: 10px">工作地点</p>
      <p>  {{item.address}}</p>
    </div>
  </div>
  <el-pagination
      background
      layout="prev, pager, next"
      :page-size="pagesize"
      :current-page="page"
      :total="total"
      @current-change="skppage">
  </el-pagination>
</div>
</template>

<script>
import axios from "@/utils/request";

export default {
  name: "index",
  data:()=>{
    return {
      datalist:[],
      pagesize:10,
      page:1,
      total:''
    }
  },
  mounted() {
  this.getliast()
  },
  methods:{
    getliast(){
      axios.get('/renren-api/api/Recruit/xjyrecruit/page?'+'limit='+this.pagesize+'&page='+this.page).then((res)=>{
        if(res.status=='200'){
          this.datalist=res.data.data.list;
          // console.log(res.data,"111111111111")
          this.total=res.data.data.total
        }
      })
    },
    toinfo(value){
      this.$router.push('/recruitment/info?id='+value)
    },
    skppage(e){
      this.page=e;
      this.getliast()
    }
  }
}
</script>

<style scoped>
.mainlist{
  width: 100%;
  height: 130px;
  background:url("../../assets/rowboder.png");
  background-size: 100% 100%;
  padding: 3%;
  display: flex;
  border-radius: 10px;
}
.mainlist>div:nth-child(1){
  width: 50%;
  height: 100%;
  /*background-color: bisque;*/
}
.mainlist>div:nth-child(2){
  width: 50%;
  height: 100%;
  /*background-color: bisque;*/

}
.mainlist:hover{
  background-color: rgba(14,100,173,0.1);
}
</style>